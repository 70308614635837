import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import logo from "../images/404.png"
import "../scss/modules/404.scss"

const NotFoundPage = () => (
  <Layout>
    <div className={"error-container"}>
      <Seo title="404: Not found | " />
      <img src={logo} alt="logo-404" className={"logo-error"}/>
      <h1>NOT FOUND</h1>
      <p>L'url ne correspond à aucune page.</p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#2bab9e4d" fill-opacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,154.7C274.3,160,343,192,411,176C480,160,549,96,617,85.3C685.7,75,754,117,823,154.7C891.4,192,960,224,1029,229.3C1097.1,235,1166,213,1234,208C1302.9,203,1371,213,1406,218.7L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>    </div>
  </Layout>
)

export default NotFoundPage
